export const testData = {
  methods: {
    current_data_test: function() {
      return {
        coord: {
          lon: -85.2533,
          lat: 33.5379,
        },
        weather: [
          {
            id: 800,
            main: "Clear",
            description: "clear sky",
            icon: "01d",
          },
        ],
        base: "stations",
        main: {
          temp: 45.82,
          feels_like: 41.16,
          temp_min: 45,
          temp_max: 46.4,
          pressure: 1026,
          humidity: 29,
        },
        visibility: 10000,
        wind: {
          speed: 9.22,
          deg: 330,
          gust: 21.85,
        },
        clouds: {
          all: 1,
        },
        dt: 1617320912,
        sys: {
          type: 1,
          id: 3715,
          country: "US",
          sunrise: 1617276485,
          sunset: 1617321669,
        },
        timezone: -14400,
        id: 4183899,
        name: "Somewhere",
        cod: 200,
      };
    },

    one_call_test: function() {
      return {
        lat: 33.5379,
        lon: -85.2533,
        timezone: "America/New_York",
        timezone_offset: -14400,
        current: {
          dt: 1617321197,
          sunrise: 1617276485,
          sunset: 1617321669,
          temp: 45.5,
          feels_like: 40.77,
          pressure: 1026,
          humidity: 29,
          dew_point: 16.99,
          uvi: 0,
          clouds: 1,
          visibility: 10000,
          wind_speed: 9.22,
          wind_deg: 330,
          wind_gust: 21.85,
          weather: [
            {
              id: 800,
              main: "Clear",
              description: "clear sky",
              icon: "01d",
            },
          ],
        },
        hourly: [
          {
            dt: 1617318000,
            temp: 45.5,
            feels_like: 39.83,
            pressure: 1026,
            humidity: 29,
            dew_point: 16.99,
            uvi: 0.27,
            clouds: 1,
            visibility: 10000,
            wind_speed: 11.77,
            wind_deg: 338,
            wind_gust: 21.7,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617321600,
            temp: 43.43,
            feels_like: 37.71,
            pressure: 1026,
            humidity: 40,
            dew_point: 22.01,
            uvi: 0,
            clouds: 1,
            visibility: 10000,
            wind_speed: 10.49,
            wind_deg: 337,
            wind_gust: 22.37,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617325200,
            temp: 40.37,
            feels_like: 34.47,
            pressure: 1028,
            humidity: 51,
            dew_point: 24.64,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 9.17,
            wind_deg: 336,
            wind_gust: 24.45,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617328800,
            temp: 38.08,
            feels_like: 31.57,
            pressure: 1030,
            humidity: 59,
            dew_point: 25.84,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 9.35,
            wind_deg: 340,
            wind_gust: 25.61,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617332400,
            temp: 36.54,
            feels_like: 29.68,
            pressure: 1030,
            humidity: 62,
            dew_point: 25.59,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 9.31,
            wind_deg: 349,
            wind_gust: 25.57,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617336000,
            temp: 35.58,
            feels_like: 28.51,
            pressure: 1030,
            humidity: 63,
            dew_point: 24.37,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 9.31,
            wind_deg: 351,
            wind_gust: 26.24,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617339600,
            temp: 34.57,
            feels_like: 27.72,
            pressure: 1030,
            humidity: 64,
            dew_point: 23.9,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.46,
            wind_deg: 353,
            wind_gust: 26.37,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617343200,
            temp: 33.58,
            feels_like: 26.92,
            pressure: 1030,
            humidity: 66,
            dew_point: 23.67,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 7.74,
            wind_deg: 352,
            wind_gust: 25.72,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617346800,
            temp: 32.92,
            feels_like: 25.79,
            pressure: 1030,
            humidity: 68,
            dew_point: 23.67,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.28,
            wind_deg: 358,
            wind_gust: 24.99,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617350400,
            temp: 31.91,
            feels_like: 25.07,
            pressure: 1030,
            humidity: 70,
            dew_point: 23.4,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 7.47,
            wind_deg: 0,
            wind_gust: 24.43,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617354000,
            temp: 30.58,
            feels_like: 23.47,
            pressure: 1030,
            humidity: 73,
            dew_point: 22.89,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 7.45,
            wind_deg: 353,
            wind_gust: 24.63,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617357600,
            temp: 29.53,
            feels_like: 21.85,
            pressure: 1031,
            humidity: 73,
            dew_point: 22.19,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 7.99,
            wind_deg: 353,
            wind_gust: 25.5,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617361200,
            temp: 29.37,
            feels_like: 21.22,
            pressure: 1032,
            humidity: 69,
            dew_point: 20.75,
            uvi: 0,
            clouds: 1,
            visibility: 10000,
            wind_speed: 8.68,
            wind_deg: 355,
            wind_gust: 26.84,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617364800,
            temp: 29.75,
            feels_like: 21.2,
            pressure: 1032,
            humidity: 65,
            dew_point: 19.45,
            uvi: 0.12,
            clouds: 1,
            visibility: 10000,
            wind_speed: 9.51,
            wind_deg: 2,
            wind_gust: 27.87,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617368400,
            temp: 33.31,
            feels_like: 24.91,
            pressure: 1032,
            humidity: 54,
            dew_point: 18.75,
            uvi: 0.67,
            clouds: 0,
            visibility: 10000,
            wind_speed: 10.92,
            wind_deg: 5,
            wind_gust: 27.4,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617372000,
            temp: 37.85,
            feels_like: 30.31,
            pressure: 1033,
            humidity: 41,
            dew_point: 16.56,
            uvi: 1.85,
            clouds: 0,
            visibility: 10000,
            wind_speed: 11.59,
            wind_deg: 13,
            wind_gust: 21.07,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617375600,
            temp: 42.13,
            feels_like: 36.23,
            pressure: 1032,
            humidity: 34,
            dew_point: 16.11,
            uvi: 3.54,
            clouds: 0,
            visibility: 10000,
            wind_speed: 10.13,
            wind_deg: 16,
            wind_gust: 16.62,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617379200,
            temp: 45.68,
            feels_like: 40.93,
            pressure: 1032,
            humidity: 31,
            dew_point: 16.92,
            uvi: 5.44,
            clouds: 0,
            visibility: 10000,
            wind_speed: 9.37,
            wind_deg: 17,
            wind_gust: 13.91,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617382800,
            temp: 48.29,
            feels_like: 44.29,
            pressure: 1031,
            humidity: 30,
            dew_point: 18.63,
            uvi: 6.67,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.97,
            wind_deg: 15,
            wind_gust: 12.75,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617386400,
            temp: 50.5,
            feels_like: 46.67,
            pressure: 1031,
            humidity: 30,
            dew_point: 20.35,
            uvi: 6.89,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.57,
            wind_deg: 11,
            wind_gust: 11.72,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617390000,
            temp: 51.84,
            feels_like: 48.13,
            pressure: 1030,
            humidity: 30,
            dew_point: 21.67,
            uvi: 6.16,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.37,
            wind_deg: 6,
            wind_gust: 11.16,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617393600,
            temp: 52.48,
            feels_like: 48.85,
            pressure: 1029,
            humidity: 30,
            dew_point: 22.59,
            uvi: 4.49,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.32,
            wind_deg: 0,
            wind_gust: 10.76,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617397200,
            temp: 52.09,
            feels_like: 48.51,
            pressure: 1029,
            humidity: 32,
            dew_point: 23.32,
            uvi: 2.61,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.37,
            wind_deg: 359,
            wind_gust: 10.47,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617400800,
            temp: 50.79,
            feels_like: 47.17,
            pressure: 1029,
            humidity: 34,
            dew_point: 24.17,
            uvi: 1.12,
            clouds: 0,
            visibility: 10000,
            wind_speed: 8.1,
            wind_deg: 359,
            wind_gust: 10.2,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617404400,
            temp: 48.33,
            feels_like: 45.48,
            pressure: 1029,
            humidity: 45,
            dew_point: 28.4,
            uvi: 0.29,
            clouds: 0,
            visibility: 10000,
            wind_speed: 6.35,
            wind_deg: 359,
            wind_gust: 12.21,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617408000,
            temp: 41.23,
            feels_like: 38.08,
            pressure: 1030,
            humidity: 57,
            dew_point: 27.46,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 4.79,
            wind_deg: 359,
            wind_gust: 6.35,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617411600,
            temp: 38.34,
            feels_like: 35.1,
            pressure: 1031,
            humidity: 60,
            dew_point: 26.15,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 4.32,
            wind_deg: 9,
            wind_gust: 4.99,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617415200,
            temp: 37.02,
            feels_like: 33.8,
            pressure: 1031,
            humidity: 61,
            dew_point: 25.11,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 4.07,
            wind_deg: 12,
            wind_gust: 4.72,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617418800,
            temp: 36.09,
            feels_like: 32.85,
            pressure: 1031,
            humidity: 61,
            dew_point: 23.94,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.96,
            wind_deg: 359,
            wind_gust: 4.5,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617422400,
            temp: 35.31,
            feels_like: 32.11,
            pressure: 1031,
            humidity: 59,
            dew_point: 22.57,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.8,
            wind_deg: 348,
            wind_gust: 3.78,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617426000,
            temp: 34.52,
            feels_like: 31.55,
            pressure: 1031,
            humidity: 58,
            dew_point: 21.52,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.49,
            wind_deg: 344,
            wind_gust: 3.38,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617429600,
            temp: 33.73,
            feels_like: 31.01,
            pressure: 1031,
            humidity: 58,
            dew_point: 20.79,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.2,
            wind_deg: 352,
            wind_gust: 3.22,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617433200,
            temp: 32.95,
            feels_like: 30.31,
            pressure: 1031,
            humidity: 59,
            dew_point: 20.26,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.06,
            wind_deg: 6,
            wind_gust: 3.02,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617436800,
            temp: 32.13,
            feels_like: 29.05,
            pressure: 1031,
            humidity: 60,
            dew_point: 19.98,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.31,
            wind_deg: 13,
            wind_gust: 3.13,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617440400,
            temp: 31.37,
            feels_like: 28.42,
            pressure: 1031,
            humidity: 61,
            dew_point: 19.63,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 3.13,
            wind_deg: 20,
            wind_gust: 3.13,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617444000,
            temp: 30.78,
            feels_like: 30.78,
            pressure: 1032,
            humidity: 61,
            dew_point: 19.18,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 2.84,
            wind_deg: 43,
            wind_gust: 2.73,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617447600,
            temp: 30.34,
            feels_like: 30.34,
            pressure: 1032,
            humidity: 61,
            dew_point: 18.73,
            uvi: 0,
            clouds: 0,
            visibility: 10000,
            wind_speed: 2.24,
            wind_deg: 52,
            wind_gust: 2.1,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01n",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617451200,
            temp: 32.52,
            feels_like: 32.52,
            pressure: 1033,
            humidity: 57,
            dew_point: 19.27,
            uvi: 0.14,
            clouds: 0,
            visibility: 10000,
            wind_speed: 1.23,
            wind_deg: 54,
            wind_gust: 1.23,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617454800,
            temp: 39.7,
            feels_like: 39.7,
            pressure: 1033,
            humidity: 48,
            dew_point: 21.74,
            uvi: 0.75,
            clouds: 0,
            visibility: 10000,
            wind_speed: 0.87,
            wind_deg: 146,
            wind_gust: 1.72,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617458400,
            temp: 45.73,
            feels_like: 45.73,
            pressure: 1033,
            humidity: 42,
            dew_point: 24.31,
            uvi: 2.09,
            clouds: 33,
            visibility: 10000,
            wind_speed: 2.71,
            wind_deg: 204,
            wind_gust: 4.41,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617462000,
            temp: 50.94,
            feels_like: 47.46,
            pressure: 1032,
            humidity: 37,
            dew_point: 26.22,
            uvi: 4.02,
            clouds: 27,
            visibility: 10000,
            wind_speed: 2.75,
            wind_deg: 201,
            wind_gust: 3.69,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617465600,
            temp: 54.48,
            feels_like: 51.22,
            pressure: 1032,
            humidity: 34,
            dew_point: 26.8,
            uvi: 5.93,
            clouds: 29,
            visibility: 10000,
            wind_speed: 3.15,
            wind_deg: 217,
            wind_gust: 3.6,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617469200,
            temp: 56.86,
            feels_like: 53.74,
            pressure: 1031,
            humidity: 32,
            dew_point: 27.57,
            uvi: 7.27,
            clouds: 25,
            visibility: 10000,
            wind_speed: 3.69,
            wind_deg: 231,
            wind_gust: 4.18,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617472800,
            temp: 58.46,
            feels_like: 55.47,
            pressure: 1030,
            humidity: 31,
            dew_point: 28.56,
            uvi: 7.5,
            clouds: 35,
            visibility: 10000,
            wind_speed: 3.8,
            wind_deg: 234,
            wind_gust: 4.05,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617476400,
            temp: 59.67,
            feels_like: 56.79,
            pressure: 1029,
            humidity: 31,
            dew_point: 29.39,
            uvi: 6.64,
            clouds: 48,
            visibility: 10000,
            wind_speed: 4.09,
            wind_deg: 237,
            wind_gust: 4.54,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617480000,
            temp: 60.01,
            feels_like: 57.16,
            pressure: 1028,
            humidity: 31,
            dew_point: 29.95,
            uvi: 4.84,
            clouds: 24,
            visibility: 10000,
            wind_speed: 4.27,
            wind_deg: 233,
            wind_gust: 5.08,
            weather: [
              {
                id: 801,
                main: "Clouds",
                description: "few clouds",
                icon: "02d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617483600,
            temp: 59.67,
            feels_like: 56.88,
            pressure: 1028,
            humidity: 33,
            dew_point: 30.72,
            uvi: 2.82,
            clouds: 18,
            visibility: 10000,
            wind_speed: 4.18,
            wind_deg: 237,
            wind_gust: 5.88,
            weather: [
              {
                id: 801,
                main: "Clouds",
                description: "few clouds",
                icon: "02d",
              },
            ],
            pop: 0,
          },
          {
            dt: 1617487200,
            temp: 59.13,
            feels_like: 56.43,
            pressure: 1027,
            humidity: 36,
            dew_point: 32.54,
            uvi: 1.19,
            clouds: 14,
            visibility: 10000,
            wind_speed: 3.96,
            wind_deg: 230,
            wind_gust: 6.08,
            weather: [
              {
                id: 801,
                main: "Clouds",
                description: "few clouds",
                icon: "02d",
              },
            ],
            pop: 0,
          },
        ],
        daily: [
          {
            dt: 1617296400,
            sunrise: 1617276485,
            sunset: 1617321669,
            temp: {
              day: 46.35,
              min: 36.54,
              max: 48.43,
              night: 36.54,
              eve: 45.5,
              morn: 37.45,
            },
            feels_like: {
              day: 39.87,
              night: 29.86,
              eve: 39.83,
              morn: 29.86,
            },
            pressure: 1027,
            humidity: 33,
            dew_point: 19.29,
            wind_speed: 15.35,
            wind_deg: 335,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            clouds: 0,
            pop: 0.02,
            uvi: 6.59,
          },
          {
            dt: 1617382800,
            sunrise: 1617362804,
            sunset: 1617408112,
            temp: {
              day: 48.29,
              min: 29.37,
              max: 52.48,
              night: 36.09,
              eve: 48.33,
              morn: 29.37,
            },
            feels_like: {
              day: 44.29,
              night: 21.22,
              eve: 45.48,
              morn: 21.22,
            },
            pressure: 1031,
            humidity: 30,
            dew_point: 18.63,
            wind_speed: 8.97,
            wind_deg: 15,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            clouds: 0,
            pop: 0,
            uvi: 6.89,
          },
          {
            dt: 1617469200,
            sunrise: 1617449125,
            sunset: 1617494556,
            temp: {
              day: 56.86,
              min: 30.34,
              max: 60.01,
              night: 43.39,
              eve: 55.99,
              morn: 30.34,
            },
            feels_like: {
              day: 53.74,
              night: 30.34,
              eve: 53.83,
              morn: 30.34,
            },
            pressure: 1031,
            humidity: 32,
            dew_point: 27.57,
            wind_speed: 3.69,
            wind_deg: 231,
            weather: [
              {
                id: 802,
                main: "Clouds",
                description: "scattered clouds",
                icon: "03d",
              },
            ],
            clouds: 25,
            pop: 0,
            uvi: 7.5,
          },
          {
            dt: 1617555600,
            sunrise: 1617535445,
            sunset: 1617581000,
            temp: {
              day: 64.56,
              min: 39.83,
              max: 64.56,
              night: 50.68,
              eve: 54.19,
              morn: 39.83,
            },
            feels_like: {
              day: 62.51,
              night: 39.83,
              eve: 52.56,
              morn: 39.83,
            },
            pressure: 1026,
            humidity: 38,
            dew_point: 39.07,
            wind_speed: 4.81,
            wind_deg: 273,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            clouds: 6,
            pop: 0,
            uvi: 7.14,
          },
          {
            dt: 1617642000,
            sunrise: 1617621766,
            sunset: 1617667444,
            temp: {
              day: 69.44,
              min: 46.27,
              max: 69.57,
              night: 54.32,
              eve: 58.35,
              morn: 46.72,
            },
            feels_like: {
              day: 68.14,
              night: 45.95,
              eve: 57.09,
              morn: 45.95,
            },
            pressure: 1022,
            humidity: 44,
            dew_point: 46.71,
            wind_speed: 3.69,
            wind_deg: 278,
            weather: [
              {
                id: 800,
                main: "Clear",
                description: "clear sky",
                icon: "01d",
              },
            ],
            clouds: 5,
            pop: 0,
            uvi: 7.34,
          },
          {
            dt: 1617728400,
            sunrise: 1617708087,
            sunset: 1617753888,
            temp: {
              day: 66.65,
              min: 52.23,
              max: 69.94,
              night: 55.63,
              eve: 60.75,
              morn: 55.38,
            },
            feels_like: {
              day: 65.75,
              night: 54,
              eve: 60.1,
              morn: 54,
            },
            pressure: 1020,
            humidity: 58,
            dew_point: 51.91,
            wind_speed: 4.52,
            wind_deg: 280,
            weather: [
              {
                id: 804,
                main: "Clouds",
                description: "overcast clouds",
                icon: "04d",
              },
            ],
            clouds: 100,
            pop: 0,
            uvi: 0.08,
          },
          {
            dt: 1617814800,
            sunrise: 1617794409,
            sunset: 1617840332,
            temp: {
              day: 75.4,
              min: 52.99,
              max: 75.69,
              night: 61.05,
              eve: 64.02,
              morn: 54.18,
            },
            feels_like: {
              day: 74.89,
              night: 53.62,
              eve: 63.64,
              morn: 53.62,
            },
            pressure: 1015,
            humidity: 48,
            dew_point: 54.7,
            wind_speed: 7.72,
            wind_deg: 223,
            weather: [
              {
                id: 801,
                main: "Clouds",
                description: "few clouds",
                icon: "02d",
              },
            ],
            clouds: 24,
            pop: 0,
            uvi: 1,
          },
          {
            dt: 1617901200,
            sunrise: 1617880731,
            sunset: 1617926776,
            temp: {
              day: 72.91,
              min: 59.65,
              max: 79.63,
              night: 65.48,
              eve: 66.99,
              morn: 61,
            },
            feels_like: {
              day: 73.06,
              night: 61.16,
              eve: 67.53,
              morn: 61.16,
            },
            pressure: 1011,
            humidity: 67,
            dew_point: 61.72,
            wind_speed: 10.42,
            wind_deg: 228,
            weather: [
              {
                id: 500,
                main: "Rain",
                description: "light rain",
                icon: "10d",
              },
            ],
            clouds: 98,
            pop: 0.67,
            rain: 2.6,
            uvi: 1,
          },
        ],
        alerts: [
          {
            sender_name: "NWS Atlanta (Northern and Central Georgia)",
            event: "Freeze Warning",
            start: 1617336000,
            end: 1617372000,
            description:
              "...FREEZE WARNING REMAINS IN EFFECT FROM MIDNIGHT TONIGHT TO\n10 AM EDT FRIDAY...\n...FREEZE WATCH REMAINS IN EFFECT FROM LATE FRIDAY NIGHT THROUGH\nSATURDAY MORNING...\n...WIND ADVISORY HAS EXPIRED...\n* WHAT...For the Freeze Warning, sub-freezing temperatures as\nlow as 28 expected. For the Freeze Watch, sub-freezing\ntemperatures as low as 30 possible.\n* WHERE...Schley, Macon, Peach and Houston Counties.\n* WHEN...For the Freeze Warning, from midnight tonight to 10 AM\nEDT Friday. For the Freeze Watch, from late Friday night\nthrough Saturday morning.\n* IMPACTS...Frost and freeze conditions will kill crops, other\nsensitive vegetation and possibly damage unprotected outdoor\nplumbing.",
          },
          {
            sender_name: "NWS Atlanta (Northern and Central Georgia)",
            event: "Freeze Watch",
            start: 1617422400,
            end: 1617458400,
            description:
              "...FREEZE WARNING REMAINS IN EFFECT FROM MIDNIGHT TONIGHT TO\n10 AM EDT FRIDAY...\n...FREEZE WATCH REMAINS IN EFFECT FROM LATE FRIDAY NIGHT THROUGH\nSATURDAY MORNING...\n...WIND ADVISORY HAS EXPIRED...\n* WHAT...For the Freeze Warning, sub-freezing temperatures as\nlow as 28 expected. For the Freeze Watch, sub-freezing\ntemperatures as low as 30 possible.\n* WHERE...Schley, Macon, Peach and Houston Counties.\n* WHEN...For the Freeze Warning, from midnight tonight to 10 AM\nEDT Friday. For the Freeze Watch, from late Friday night\nthrough Saturday morning.\n* IMPACTS...Frost and freeze conditions will kill crops, other\nsensitive vegetation and possibly damage unprotected outdoor\nplumbing.",
          },
        ],
      };
    },
  },
};
